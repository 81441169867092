<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account overview</router-link>
                    &gt; <router-link :to="{ name: 'account-settings', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId } }">Account settings</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                            <v-toolbar-title>
                                Close account
                            </v-toolbar-title>
                            <v-spacer/>
                            <v-progress-circular indeterminate size="16" width="2" color="white" v-if="!account || !accountBillingSummary"/>
                        </v-toolbar>
                        <v-card-text v-if="account && accountBillingSummary && !accountBillingSummary.is_open">
                            <p class="mb-0 pb-0">
                                This account is already closed.
                            </p>
                        </v-card-text>
                        <v-card-text v-if="account && accountBillingSummary && accountBillingSummary.is_open">

                            <p class="mb-0 pb-0">
                                Account status: open
                            </p>
                            <p class="mb-0 pb-0">
                                Balance due: {{ balanceDisplay }}
                                <span v-if="accountBillingSummary.credit > 0">Credit: {{ creditDisplay }}</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <!-- TODO: if payment is due, indicate due date and whether we will automatically charge a card  on that date or if customer has to pay some other way, or if the credit card info is invalid and needs to be updated -->
                                <!-- <a href="#" @click="navigateToBilling"> --> <!-- TODO: navigate to "pay my balance" page -->
                                {{ accountBillingSummary.is_payment_due ? 'Payment is due' : 'No payment is due at this time' }}
                                {{ accountBillingSummary.is_payment_late ? '(late)' : '' }}
                                <!-- </a> -->
                            </p>
                            <!-- TODO: if there are any active subscriptoins, they must be cancelled first;  that way when each one is cancelled it might generate a final balance to pay (if there was metered billing) and so when user returns here with all services canceleld we'll know the account balance is correct and final before we say if there's anything they need to pay -->

                            <!-- if there's a balance, you must first pay the balance -->
                            <p v-if="!isCloseAccountEnabled && accountBillingSummary.balance_csu > 0">Before you can close this account, you must first <TextButton @click="redirectToPayBalance" color="blue" underline>pay the balance</TextButton>.</p>
                            <!-- Then return to this page to continue. -->

                            <!-- TODO: if account requires approval from more than one administrator to close it, and this approval hasn't yet been given, we need to provide a button here to send a notification to the other admins, and user can't continue until enough other admins or the right aother admins approved it -->

                            <!-- This is now showing in the navbar so it's redundant here -->
                            <!-- <p class="text-overline mb-0">Account name</p>
                            <p class="mb-0 pb-0">
                                {{ account.name }}
                            </p> -->

                            <p class="mb-0 mt-4" :class="{ 'grey--text': !isCloseAccountEnabled }">Tap on the button below to close this account. When an account is closed:</p>
                            <ul class="mt-2 mb-4">
                                <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">All other users will be removed from the account</span></li> <!-- TODO: show only if there are any non-admin users in the account right now; the owner stays on the account because might need ot delete that account also at the client application, or come back and see past transactions while it's still archived -->
                                <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">No new transactions are allowed on the account</span></li> <!-- TODO: show only if there are any non-admin users in the account right now -->
                                <!-- <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">Pending orders will be cancelled</span></li> --> <!-- TODO: show only if there are any pending orders in the account right now; alternatively, we could say that all orders must be completed before the account cna be closed , so it can't be closed while there are pending orders ; customer can try to cancel them first if they aare in a state where they can be cancelled (i.e. not already shipped) and then close the account -->
                                <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">All orders are final, no refunds or changes on past orders</span></li> <!-- TODO only show if account has contacts (anything beyond free plan) -->
                                <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">Any account credits and discounts will be forfeit</span></li> <!-- TODO only show if account has a credit > 0 ; we don't pay credits out in cash, they can only apply towards future orders ; ALSO, in our terms of service we  need to state that account credits are generally non-refundable, but if any of them ARE refundable that's a situation that blocks closing the account, because customer either needs to get that refund issued immediately before we close or they need to acknowledge that they will forfeit the refund on a separate form and we'll mark it as forefeited and then they can close the account -->
                                <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">All services and subscriptions will be cancelled</span></li> <!-- TODO: only show if the account has linked services -->
                                <!-- <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">The account will no longer be accessible</span></li> --> <!-- TODO: how will user get rid of the account from the accounts menu? do we need a "hide" stting? and in the account list they could hvae a switch/toggle for showing hidden accounts so they could still get to it later after they've hidden it -->
                                <!-- <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">Account data will be archived and eventually deleted</span></li> --> <!-- TODO: only show when we define the data retention policy -->
                                <!-- <li><span :class="{ 'grey--text': !isCloseAccountEnabled }">You may request to delete PII during the data retention period</span></li> --> <!-- TODO: only show when we define the data anonymization policy (NOTE: there's an item in the business ideas list about how to do this nicely)   ALSO TODO: this can be a checkbox on this form "Anonymize archived account data by deleting my PII" or something along those lines -->
                            </ul>

                            <!-- TODO: if there are custom branded sites, show them here -->
                            <!-- <template v-if="domainList">
                            <p class="text-overline mb-0 mt-4">Domains ({{ domainList.length }})</p>
                            <p class="mb-0 pb-0">
                                <v-chip :v-for="(domain, idx) in domainList" :key="idx">{{ domain.name }}</v-chip>
                            </p>
                            </template> -->

                            <p class="text-overline mb-0 mt-4" :class="{ 'grey--text': !isCloseAccountEnabled }">
                                Confirmation
                                <!-- <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="amber--text text--darken-2 text-body-1"/> -->
                            </p>
                            <!-- TODO: we should have a confirmation checkbox for every item to be deleted: "Delete all documents", "Delete all contacts", "Remove all users", "Stop data leak detection", "Delete custom-branded sites", but only show items that apply to their account; for example if their account has any discounts applied, "Forfeit all applied discounts", etc. -->
                            <v-checkbox color="amber darken-2" v-model="closeConfirmation1" class="mt-0" :disabled="!isCloseAccountEnabled">
                                <template #label>
                                    <div>
                                        I want to close this account (<strong>{{ account.name }}</strong>)
                                    </div>
                                </template>
                            </v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="closeConfirmation2" label="I understand it cannot be re-opened, but I can open a new account instead" class="mt-0" :disabled="!isCloseAccountEnabled"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="closeConfirmation3" class="mt-0" :disabled="!isCloseAccountEnabled">
                                <template #label>
                                    <div>
                                    I understand this action is not reversible, and that customer support will <strong><em>not</em></strong> be able to help me re-open the account.
                                    </div>
                                </template>
                            </v-checkbox>

                            <v-alert type="warning" border="left" dense v-if="isCloseAccountConfirmed">
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                            </v-alert>

                            <v-btn color="red" class="white--text" @click="closeAccount" :disabled="!isCloseAccountConfirmed">Close Account</v-btn>
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to close this account.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="closeAccountImmediately">Delete Account</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        TextButton,
    },
    data: () => ({
        accountBillingSummary: null,
        isAccountBillingSummaryReady: false, // prevents flicker on related UI items
        domainList: null,
        error: null,
        closeConfirmation1: false,
        closeConfirmation2: false,
        closeConfirmation3: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            palette: (state) => state.palette,
            brand: (state) => state.brand,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isCloseAccountEnabled() {
            // close account is only enabled when the account is open and has zero balance
            return this.accountBillingSummary && this.accountBillingSummary.is_open && this.accountBillingSummary.balance_csu === 0;
        },
        isCloseAccountConfirmed() {
            return this.closeConfirmation1 && this.closeConfirmation2 && this.closeConfirmation3;
        },
        balanceDisplay() {
            if (Number.isFinite(this.accountBillingSummary?.balance)) {
                const display = new Intl.NumberFormat('en-US', {
                    currency: this.accountBillingSummary.currency,
                    style: 'currency',
                }).format(this.accountBillingSummary.balance);
                return display;
            }
            return '';
        },
        creditDisplay() {
            if (Number.isFinite(this.accountBillingSummary?.credit)) {
                const display = new Intl.NumberFormat('en-US', {
                    currency: this.accountBillingSummary.currency,
                    style: 'currency',
                }).format(this.accountBillingSummary.credit);
                return display;
            }
            return '';
        },
    },
    methods: {
        async loadAccountBillingSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountBillingSummary: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).self.check({ item: 'billing' });
                console.log(`loadAccountBillingSummary: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountBillingSummary = response; // {"is_open":true,"is_payment_due":true,"is_payment_late":false,"balance_amount":"100.00","credit_amount":"20.00","status_on":1234567890}
                } else {
                    this.accountBillingSummary = null;
                }
            } catch (err) {
                console.error('loadAccountBillingSummary: failed to load billing summary report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountBillingSummary: false });
                this.isAccountBillingSummaryReady = true;
            }
        },
        async closeAccount() {
            try {
                this.$store.commit('loading', { closeAccount: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).self.close({ is_confirmed: this.isCloseAccountConfirmed });
                if (response?.isClosed) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Closed account', message: this.$route.params.accountId });
                    this.$store.commit('account', null);
                    this.$nav.set('account_id', null);
                    this.$nav.replace({ name: 'user-account-list' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to close account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to close account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to close account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { closeAccount: false });
            }
        },
        /**
         * Service admin can close the account and data immediately
         */
        async closeAccountImmediately() {
            try {
                this.$store.commit('loading', { closeAccountImmediately: true });
                // TODO: disable the submit button while processing so user doesn't press it twice
                const response = this.$client.main().service.closeAccount({ accountId: this.$route.params.accountId });
                if (response?.isClosed) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Closed account', message: this.$route.params.accountId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to close account', message: this.$route.params.accountId });
                }
            } catch (err) {
                console.error('failed to close account', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to close account', message: this.$route.params.accountId });
            } finally {
                this.$store.commit('loading', { closeAccountImmediately: false });
            }
        },
        /* TODO redirect to payemtnw tih some kind of item in cart for current balance ... like custom damount due ?
        async redirectToPayBalance() {
            try {
                this.error = false;
                this.$store.commit('loading', { redirectToPayBalance: true });
                const response = await this.$client.site(this.brandprofile).account(this.$route.params.accountId).unicornsprings.connect({ intent: 'close_account' });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('redirectToPayBalance: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { redirectToPayBalance: false });
            }
        },
        */
        async loadAccount(accountId) {
            await this.$store.dispatch('loadAccount', { accountId });
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
                // get account billing status
                await this.loadAccountBillingSummary();
            }
        },
    },
    mounted() {
        if (!this.account) {
            this.loadAccount(this.$route.params.accountId);
        }
        this.loadAccountBillingSummary();
    },
};
</script>
